<template>
  <div class="fp-wrapper">
    <div class="modal-window">
      <loading-block v-if="ref_loading" />
      <template v-else>
        <div class="content content_form">
          <form-header />
          <h2 class="h2 h2_bold content__h2">Вход</h2>

          <form-observer :validation-schema="loginSchema" @submit="onSubmit">
            <input-text
              name="email"
              type="email"
              label-text="Электронная почта"
              placeholder="Введите e-mail"
            />
            <input-password
              name="password"
              label-text="Пароль"
              restore-link="/restore"
              :is-show-password="true"
              placeholder="Введите пароль"
            />
            <div class="content__section">
              <button
                class="button"
                :class="{'button_disabled': loading }"
              >
                Войти
              </button>
            </div>
          </form-observer>
        </div>
        <div class="content content_quick_link">
          <section class="content__section text-center">
            <strong>Нет аккаунта? <a href="/registration">Зарегистрируйтесь</a></strong>
          </section>
        </div>
      </template>
    </div>
  </div>
</template>

<script>
import { Form as FormObserver } from 'vee-validate'
import InputText from '@/components/common/InputText'
import InputPassword from '@/components/common/InputPassword'
import { loginSchema } from '@/validation'
import { mapActions } from 'vuex'
import FormHeader from '@/components/common/FormHeader'
import LoadingBlock from '@/components/common/LoadingBlock'
import Cookies from 'js-cookie'

export default {
  name: 'Login',
  components: {
    FormObserver,
    InputText,
    InputPassword,
    FormHeader,
    LoadingBlock
  },
  data () {
    return {
      loginSchema,
      loading: false,
      ref_loading: false
    }
  },
  mounted () {
    const refData = Cookies.get('ref_data')
    if (refData) {
      this.ref_loading = true
      Cookies.remove('ref_data', { domain: '.nalozhka.ru' })
      this.onSubmit(JSON.parse(decodeURIComponent(refData)))
    }
  },
  methods: {
    ...mapActions([
      'setUser'
    ]),
    onSubmit (values) {
      this.loading = true
      this.$axios.post(this.getPathRequest('login'), values)
        .then(response => {
          this.setUser(response.data)
          this.$router.push({ name: 'Main' })
        })
        .catch(error => {
          this.openModal('ErrorModal', {
            title: 'Ошибка аутентификации',
            text: error.response.data.message
              ? error.response.data.message
              : 'Во время входа произошла ошибка. Пожалуйста, попробуйте снова или обратитесь к нам в тех. поддержку.'
          })
        })
        .finally(() => {
          this.loading = false
          this.ref_loading = false
        })
    }
  }
}
</script>
